<template>
  <div>
    <b-card>
      <div slot="header">
        <strong>마진설정 검색 </strong>
      </div>
      <div>
        <b-button class="ml-1 mb-1" variant="outline-success" v-for="s in shop" v-if="s.pageCnt" size="sm" @click="selectedShop = s">{{s.shop_id}}. {{s.boutique}}</b-button>
      </div>
      <b-row>
        <b-col cols="8">
          <v-select v-model="selectedShop" :options="shop" placeholder="SHOP을 선택해주세요" @input="getShop"></v-select>
        </b-col>
        <b-col>
          <label v-if="selectedShop && stat" class="col-form-label">
            상품수: <b-badge variant="light">{{stat.goodsCount}}</b-badge>,
            규칙 페이지 수: <b-badge variant="light">{{selectedShop.pageCnt || 0}}</b-badge>
            총 규칙 수: <b-badge variant="light">{{selectedShop.ruleCnt || 0}}</b-badge>
          </label>
        </b-col>
      </b-row>
    </b-card>
    <b-card>
      <div slot="header">
        <strong>마진규칙 추가</strong>
      </div>
      <b-form>
        <b-tabs v-model="formIndex">
          <b-tab title="그룹으로 지정" active>
            <b-row class="mb-3">
              <b-col cols="12" lg="4">
                <div class="clearfix">
                  <label class="mt-1">카테고리<span v-if="category.length">: {{category.length}}개</span></label>
                  <div class="pull-right">
                    <b-form-checkbox v-if="selectedShop" v-model="havingCategoryOnly" name="check-button" inline @update:indeterminate="setStat">
                      존재하는 카테고리만
                    </b-form-checkbox>
                    <b-button class=" btn-sm m-1" variant="warning" @click="form.category = []">초기화</b-button>
                  </div>
                </div>
                <v-select v-model="form.category" multiple :options="category" placeholder="전체 카테고리"></v-select>
              </b-col>
              <b-col cols="12" lg="4">
                <div class="clearfix">
                  <label class="mt-1">브랜드<span v-if="brand.length">: {{brand.length}}개</span></label>
                  <div class="pull-right">
                    <b-form-checkbox v-if="selectedShop" v-model="havingBrandOnly" name="check-button" inline @update:indeterminate="setStat">
                      존재하는 브랜드만
                    </b-form-checkbox>
                    <b-button class="btn-sm m-1" variant="warning" @click="form.brand = []">초기화</b-button>
                  </div>
                </div>
                <v-select v-model="form.brand" multiple :options="brand" placeholder="전체 브랜드"></v-select>
              </b-col>
              <b-col cols="12" lg="4">
                <div class="clearfix">
                  <label class="mt-1">시즌<span v-if="season.length">: {{season.length}}개</span></label>
                  <b-button class="pull-right btn-sm m-1" variant="warning" @click="form.season = []">초기화</b-button>
                </div>
                <v-select v-model="form.season" multiple :options="season" placeholder="전체 시즌"></v-select>
              </b-col>
            </b-row>
            <div class="mb-3">
              <label class="mt-1">발란코드</label>
              <b-form-textarea id="goodsNo" :rows="2" v-model.trim="form.goods_no" placeholder="goods_no를 입력해주세요"></b-form-textarea>
              <small class="text-muted">콤마, 띄어쓰기, 탭, 엔터 등 숫자 이외의 모든 것으로 구분된 goods_no를 입력해주세요</small>
            </div>
            <b-row class="mb-3">
              <b-col cols="4">
                <label class="mt-1">마진율</label>
                <b-form inline>
                  <b-input type="text" v-model.number="form.rate"></b-input> %
                </b-form>
              </b-col>
              <b-col cols="4">
                <label class="mt-1">비고</label>
                <b-form>
                  <b-input type="text" v-model="form.desc" placeholder="마진설정의 이유를 적어주세요"></b-input>
                </b-form>
              </b-col>
            </b-row>
          </b-tab>
          <b-tab title="발란코드로 직접 지정">
            <div class="mb-3">
              <label class="mt-1">발란코드 및 가격</label>
              <b-form-textarea id="goodsDirect" :rows="6" v-model.trim="form.goods_direct" :placeholder="`ex)\n2345678\t370000\n2345678\t10`"></b-form-textarea>
              <small class="text-muted">goods_no(공백 or 탭)(가격 or 마진율%) 형태로 입력해주세요(ex - 2345678  370000 or 2345678  10)</small>
            </div>
            <b-row class="mb-3">
              <b-col cols="4">
                <label class="mt-1">비고</label>
                <b-form>
                  <b-input type="text" v-model="form.desc_direct" placeholder="가격 혹은 마진설정의 이유를 적어주세요"></b-input>
                </b-form>
              </b-col>
            </b-row>
          </b-tab>
        </b-tabs>
      </b-form>
      <div class="mt-2 clearfix">
        <b-button class="m-1" variant="primary" @click="addMarginRule('global')">GLOBAL 마진규칙 추가</b-button>
        <b-button class="m-1" variant="success" v-if="selectedShop && stat" @click="addMarginRule(selectedShop.shop_id)">SHOP 마진규칙 추가</b-button>
        <b-button class="m-1" variant="warning" @click="resetForm">초기화</b-button>
        <b-button class="m-1" variant="outline-info" v-b-toggle.excelCollapse @dragenter="excelCollapse = true">엑셀업로드</b-button>
        <b-button class="m-1 pull-right" variant="outline-primary" @click="helpModal = true">도움말</b-button>
        <b-button class="m-1 pull-right" variant="primary" v-if="selectedShop && stat">규칙에 맞는 상품들 보기</b-button>
      </div>
    </b-card>

    <b-collapse id="excelCollapse" v-model="excelCollapse">
      <b-row class="mb-3">
        <b-col>
          <div id="dropRule" class="drop" data-type="rule" @drop.prevent.stop="handleFile" @dragover="handleDragover" @dragenter="handleDragover" @click="_=>{$refs.xlsxRule.value = null;$refs.xlsxRule.click()}">
            <span class="title" data-type="rule">샘플 형식에 맞게 규칙을 업로드 해주세요</span>
          </div>
          <input type="file" ref="xlsxRule" data-type="rule" style="display: none" @change="handleFile">
          <b-button size="sm" class="mt-1 mr-2" @click="xlsxSample('rule')">샘플 다운로드</b-button>
          <b-button size="sm" class="mt-1" v-b-modal.rule-modal>샘플 보기</b-button>
          <b-modal id="rule-modal" size="lg" ok-only>
            <table class="table">
              <thead>
              <tr>
                <th>category</th>
                <th>brand_no</th>
                <th>season</th>
                <th>goods_no</th>
                <th>rate</th>
              </tr>
              </thead>
              <tbody>
              <tr>
                <td>009002,010002</td>
                <td>337,4620</td>
                <td>19FW</td>
                <td>1376542,2695729</td>
                <td>7</td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td>18FW,20SS</td>
                <td></td>
                <td>5</td>
              </tr>
              </tbody>
            </table>
          </b-modal>
        </b-col>
        <b-col>
          <div id="dropPrice" class="drop" data-type="price" @drop.prevent.stop="handleFile" @dragover="handleDragover" @dragenter="handleDragover" @click="_=>{$refs.xlsxPrice.value = null;$refs.xlsxPrice.click()}">
            <span class="title" data-type="price">샘플 형식에 맞게 가격을 업로드 해주세요</span>
          </div>
          <input type="file" ref="xlsxPrice" data-type="price" style="display: none" @change="handleFile">
          <b-button size="sm" class="mt-1 mr-2" @click="xlsxSample('price')">샘플 다운로드</b-button>
          <b-button size="sm" class="mt-1" v-b-modal.price-modal>샘플 보기</b-button>
          <b-modal id="price-modal" size="lg" ok-only>
            <table class="table">
              <thead>
              <tr>
                <th>goods_no</th>
                <th>price</th>
              </tr>
              </thead>
              <tbody>
              <tr>
                <td>1376542</td>
                <td>450000</td>
              </tr>
              <tr>
                <td>2695729</td>
                <td>550000</td>
              </tr>
              </tbody>
            </table>
          </b-modal>
        </b-col>
      </b-row>
    </b-collapse>

    <b-modal title="도움말" v-model="helpModal" ok-only ok-title="확인">
      - 각 샵에서 등록된 상품(goods_status:registered)만 대상으로 카운팅합니다<br/>
      - 마진규칙은 위에서부터 차례대로 비교해가며 적용합니다. 예를 들어 009 : 5%, 009002003 : 8% 순으로 되어있다면 009에서 먼저 일치했기 때문에 009002003 인 상품들도 5% 가 적용됩니다<br/>
      - Global 규칙을 먼저 시도하고 그 다음 샵별 규칙을 시도하게 됩니다<br/>
      <br/>
      - 엑셀 업로드는 goods_no 마다 별도 가격을 매길 때 사용합니다
    </b-modal>
    <b-modal title="마진 추가" v-model="uploadTargetModal" ok-only ok-variant="secondary" ok-title="취소">
      비고<br/>
      <b-input class="mb-2" v-model="uploadDesc" placeholder="마진설정의 이유를 적어주세요"></b-input>
      <b-button class="mb-2" variant="primary" block @click="uploadMarginRule('global')">GLOBAL 에 추가</b-button>
      <b-button v-if="selectedShop" variant="success" block @click="uploadMarginRule(selectedShop.shop_id)">SHOP({{selectedShop.value}}) 에 추가</b-button>
    </b-modal>

    <h5>GLOBAL 규칙</h5>
    <b-tabs v-model="globalPageIndex">
      <template v-slot:tabs-end>
        <b-nav-item @click.prevent="newGlobalPage" href="#"><b>+</b></b-nav-item>
      </template>
      <b-tab v-for="(p, idx) in globalRules" :active="p.active" :key="idx">
        <template v-slot:title>
          <span v-if="p.name">{{p.name}}</span>
          <span v-else>GLOBAL 규칙 Page {{p.page}}</span>
          &nbsp;<b-badge v-if="p.active" variant="primary">ACTIVE</b-badge>
        </template>
        <div class="clearfix mb-2">
          <b-button class="pull-right ml-2" variant="danger" size="sm" @click="removeGlobalPage(p.page)">현재 페이지 삭제</b-button>
          <b-button v-if="p.active" class="pull-right" variant="warning" size="sm" @click="activateGlobalPage(p.page, false)">현재 페이지를 비활성화</b-button>
          <b-button v-else class="pull-right" variant="success" size="sm" @click="activateGlobalPage(p.page, true)">현재 페이지를 활성화</b-button>
          <b-button class="pull-right mr-2" variant="secondary" size="sm" @click="renameGlobalPage(p.page)">페이지명 수정</b-button>
        </div>
        <b-card no-body class="mb-1" bg-variant="primary">
          <b-card-body class="py-3">
            <b-row style="width:calc(100% - 100px)">
              <b-col cols="1" class="text-center">적용순서</b-col>
              <b-col cols="1" class="text-center">마진율</b-col>
              <b-col>카테고리</b-col>
              <b-col>브랜드</b-col>
              <b-col>시즌</b-col>
              <b-col>발란코드</b-col>
              <b-col>비고</b-col>
            </b-row>
          </b-card-body>
        </b-card>
        <draggable v-model="globalRules[idx].data" @start="drag=true" @end="drag=false" :options="{filter: '.detail', preventOnFilter: false}" @change="event=>reorder(event, 'global', p.page)">
          <div v-for="i in globalRules[idx].data" v-if="i" :key="i.id">
            <b-card no-body class="mb-1">
              <b-card-body class="py-2">
                <div class="d-flex align-items-center">
                  <b-row v-if="i.direct" style="width:calc(100% - 100px)">
                    <b-col cols="1" class="text-center">{{i.order}}</b-col>
                    <b-col cols="9">직접 지정 - {{i.direct}}</b-col>
                    <b-col>{{i.desc}}</b-col>
                  </b-row>
                  <b-row v-else style="width:calc(100% - 100px)">
                    <b-col cols="1" class="text-center">{{i.order}}</b-col>
                    <b-col cols="1" class="text-center"><b-badge variant="success" pill>{{i.rate}} %</b-badge></b-col>
                    <b-col>{{i.category}}</b-col>
                    <b-col>{{i.brand}}</b-col>
                    <b-col>{{i.season}}</b-col>
                    <b-col>{{i.goods_no}}</b-col>
                    <b-col>{{i.desc}}</b-col>
                  </b-row>

                  <div class="card-header-actions ml-auto">
                    <a class="pointer card-header-action btn-minimize" v-b-toggle="`global-collapse${i.id}`">
                      <i class="icon-arrow-down"></i>
                    </a>
                    <b-link href="#" class="card-header-action btn-close text-danger" @click="removeMarginRule(i, 'global')">
                      <i class="icon-close"></i>
                    </b-link>
                  </div>
                </div>
                <b-collapse :id="`global-collapse${i.id}`">
                  <div v-html="i._html" class="detail py-2"></div>
                </b-collapse>
              </b-card-body>
            </b-card>
          </div>
        </draggable>
      </b-tab>
      <template v-slot:empty>
        <div class="text-center">
          GLOBAL 마진규칙이 없습니다. + 버튼을 눌러서 추가해주세요.
        </div>
      </template>
    </b-tabs>

    <div v-if="selectedShop" class="mt-3">
      <hr />
      <h5>{{selectedShop.value}} 규칙</h5>
      <b-tabs v-model="pageIndex">
        <template v-slot:tabs-end>
          <b-nav-item @click.prevent="newPage" href="#"><b>+</b></b-nav-item>
        </template>
        <b-tab v-for="(p, idx) in rules" :title="`SHOP 규칙 Page ${p.page}`" :active="p.active" :key="p.page">
          <template v-slot:title>
            <span v-if="p.name">{{p.name}}</span>
            <span v-else>SHOP 규칙 Page {{p.page}}</span>
            &nbsp;<b-badge v-if="p.active" variant="primary">ACTIVE</b-badge>
          </template>
          <div class="clearfix mb-2">
            <b-button class="pull-right ml-2" variant="danger" size="sm" @click="removePage(p.page)">현재 페이지 삭제</b-button>
            <b-button v-if="p.active" class="pull-right" variant="warning" size="sm" @click="activatePage(p.page, false)">현재 페이지를 비활성화</b-button>
            <b-button v-else class="pull-right" variant="success" size="sm" @click="activatePage(p.page, true)">현재 페이지를 활성화</b-button>
            <b-button class="pull-right mr-2" variant="secondary" size="sm" @click="renamePage(p.page)">페이지명 수정</b-button>
          </div>
          <b-card no-body class="mb-1" bg-variant="light">
            <b-card-body class="py-3">
              <b-row style="width:calc(100% - 100px)">
                <b-col cols="1" class="text-center">적용순서</b-col>
                <b-col cols="1" class="text-center">마진율</b-col>
                <b-col>카테고리</b-col>
                <b-col>브랜드</b-col>
                <b-col>시즌</b-col>
                <b-col>발란코드</b-col>
                <b-col>비고</b-col>
              </b-row>
            </b-card-body>
          </b-card>
          <draggable v-model="rules[idx].data" @start="drag=true" @end="drag=false" @change="event=>reorder(event, null, p.page)">
            <div v-for="i in rules[idx].data" v-if="i" :key="i.id">
              <b-card no-body class="mb-1">
                <b-card-body class="py-2">
                  <div class="d-flex align-items-center">
                    <b-row v-if="i.direct" style="width:calc(100% - 100px)">
                      <b-col cols="1" class="text-center">{{i.order}}</b-col>
                      <b-col cols="9">직접 지정 - {{i.direct}}</b-col>
                      <b-col>{{i.desc}}</b-col>
                    </b-row>
                    <b-row v-else style="width:calc(100% - 100px)">
                      <b-col cols="1" class="text-center">{{i.order}}</b-col>
                      <b-col cols="1" class="text-center"><b-badge variant="success" pill>{{i.rate}} %</b-badge></b-col>
                      <b-col>{{i.category}}</b-col>
                      <b-col>{{i.brand}}</b-col>
                      <b-col>{{i.season}}</b-col>
                      <b-col>{{i.goods_no}}</b-col>
                      <b-col>{{i.desc}}</b-col>
                    </b-row>

                    <div class="card-header-actions ml-auto">
                      <b-link class="card-header-action btn-minimize" v-b-toggle="`collapse`+i.id">
                        <i class="icon-arrow-down"></i>
                      </b-link>
                      <b-link href="#" class="card-header-action btn-close text-danger" @click="removeMarginRule(i, selectedShop.shop_id)">
                        <i class="icon-close"></i>
                      </b-link>
                    </div>
                  </div>
                  <b-collapse :id="`collapse${i.id}`">
                    <div v-html="i._html" class="py-2"></div>
                  </b-collapse>
                </b-card-body>
              </b-card>
            </div>
          </draggable>
        </b-tab>
        <template v-slot:empty>
          <div class="text-center" v-if="isBusy">
            <b-spinner variant="info"></b-spinner>
          </div>
          <div class="text-center" v-else>
            SHOP 마진규칙이 없습니다. + 버튼을 눌러서 추가해주세요.
          </div>
        </template>
      </b-tabs>
    </div>
  </div>
</template>
<style scoped>
.drop {
  border: 2px dashed #bbb;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  padding: 25px;
  text-align: center;
  cursor: pointer;
  color: #bbb;
}
.drop table th, .drop table td {
  padding: 0.25rem;
}
.drop .title {
  font: 20pt bold,"Vollkorn";
}
</style>
<script>
import cTable from '@/views/TableBase.vue'
import vSelect from 'vue-select'
import {getMeta, getJson, postJson} from '@/shared/api'
import * as utils from '@/shared/utils'
import {down, readXlsx} from '@/shared/impexp'
import draggable from 'vuedraggable'
import {DELIVER_BOARD_COLUMNS, MARGIN_RULE_COLUMNS, MARGIN_PRICE_COLUMNS} from 'balaan_constants'
import store from '@/store';
const {state:S, getters:{R}} = store;

export default {
  name: 'Margin',
  title: '마진 설정',
  components: {cTable, vSelect, draggable},
  data() {
    return {
      meta: null,
      stat: null,
      shop: [],
      category: [],
      brand: [],
      season: [],
      shopMap: {},
      categoryMap: {},
      brandMap: {},
      selectedShop: null,
      havingCategoryOnly: false,
      havingBrandOnly: true,
      havingSeasonOnly: false,
      globalSeason: [],
      defaultForm: {
        category: [],
        brand: [],
        season: [],
        goods_no: '',
        rate: 5,
        desc: '',
        goods_direct: '',
        desc_direct: '',
      },
      form: {},
      formIndex: 0,
      rules: [],
      globalRules: [],
      pageIndex: 0,
      globalPageIndex: 0,
      items_detail: [],
      fields: [
        {key:'category', label:'카테고리'},
        {key:'brand', label:'브랜드'},
        {key:'season', label:'시즌'},
        {key:'rate', label:'마진율'},
        {key:'desc', label:'비고'},
        {key:'_dt', label:'설정일시'},
        {key:'_actions', label:'Actions', buttons: [{label:'상세', event:'show_details'}, {label:'제거', variant:'danger', event:'remove'}]},
      ],
      fields_detail: ['shop_id', 'img', {key:'id', label:'Goods Id / Sku Id'}, 'goodsNo', 'launch_date', 'category', {key:'goods', label:'Brand / Name'}, 'consumer', 'price', 'discount_rate'],
      perPage: 1000,
      searchBusy: false,
      isBusy: false,
      modal: false,
      helpModal: false,
      uploadTargetModal: false,
      xlsxData: [],
      uploadDesc: '',
      excelCollapse: false,
    }
  },
  async created() {
    await this.init();
    this.form = utils.clone(this.defaultForm);
    this.getMarginRule('global');
    this.setStat('global');
  },
  methods: {
    async init() {
      this.globalSeason = [];
      this.shopMap = {};
      for (let i=13; i<=20; i++) { this.globalSeason.push(`${i}SS`); this.globalSeason.push(`${i}FW`) } // 기본그룹 채워넣기
      let meta = await getMeta('shop,brand,category');
      if (!meta) return;
      let j = await getJson('/price/marginPage');
      if (!j) return;
      let {pages, active} = j, pageMap = {}, activeMap = {};
      pages.forEach(e=>pageMap[e._id] = e);
      active.forEach(e=>activeMap[e.key] = e.data_cnt);

      this.shop = meta.shop.sort((a, b) => (a.use_yn === 'n' ? 10000 : 0) + a.shop_id - (b.use_yn === 'n' ? 10000 : 0) - b.shop_id);
      this.shop.forEach(s=>{
        s.value = s.boutique;
        const labelPrefix = `${s.use_yn !== 'y' ? '[미사용] ' : ''}${s.shop_id}. ${s.boutique}`;
        if (!pageMap[s.shop_id]) {
          s.label = `${labelPrefix} (규칙 없음) `;
        } else {
          s.label = `${labelPrefix} (총 ${(pageMap[s.shop_id] || {}).cnt || 0} 개 페이지${activeMap[s.shop_id] ? ', 활성화된 규칙 있음' : ''}) `;
        }
        s.pageCnt = (pageMap[s.shop_id] || {}).cnt || 0;
        s.ruleCnt = (pageMap[s.shop_id] || {}).sum || 0;
        this.shopMap[s.shop_id] = s;
      });
      meta.season = this.globalSeason;
      this.meta = meta;
    },
    resetForm() {
      this.form = utils.clone(this.defaultForm);
    },
    async getShop() {
      if (!this.selectedShop) return this.setStat('global');

      this.searchBusy = true;
      let stat = this.stat = await getJson('/shop/shopStat?shop_id='+this.selectedShop.shop_id);
      if (stat) {
        this.setStat();
        this.resetForm();
      } else {
        this.brand = [];
        this.category = [];
        this.season = [];
      }
      this.searchBusy = false;
      this.getMarginRule(this.selectedShop.shop_id);
    },
    setStat(shop_id) {
      /**
       * shop 의 통계에 따라 카테고리, 브랜드, 시즌을 갯수와 함께 나타낸다.
       * 카테고리, 브랜드는 meta가 있기에 shop 통계에 없는 것도 보여줄 수 있다(앞으로 들어올 상품에 대비)
       */
      if (!this.meta) return;
      this.categoryMap = {};
      this.category = this.meta.category.map(e => {
        return this.categoryMap[e.category] = {...e, value: e.category, label: `${e.category} (${e.category_nm})`, cnt: 0};
      }).sort((a, b) => (a.value.length - b.value.length) * 10 + a.value.localeCompare(b.value));
      this.brandMap = {};
      this.brand = this.meta.brand.filter(e => !e.disabled).map(e => {
        return this.brandMap[e.brand_no] = {...e, value: e.brand_no, label: `${e.brand_no}. ${e.brand_nm} (${e.brand_nm_kr})`, cnt: 0};
      }).sort((a, b) => a.label.localeCompare(b.label));
      this.season = this.meta.season.map(e => {
        return {value: e, label: e};
      }).sort((a, b) => a.value.localeCompare(b.value));
      if (shop_id === 'global') return;

      if (!this.stat) return;
      let stat = this.stat;
      stat.goodsCount = 0;

      stat.category.forEach(e=>{
        if (e._id && e._id.substring(e._id.length - 3, e._id.length) === '001') e._id = e._id.substring(0, e._id.length - 3); // 001 로 끝나는 '전체' 카테고리
        let c = this.categoryMap[e._id];
        if (!c) return this.category.push({value:e._id, label:`[카테고리없음] ${utils.ifEmpty(utils.ifNull(e._id, '(null)'), '(빈 문자열)')} : ${e.cnt} 개`, cnt:e.cnt});
        c.cnt = e.cnt;
        c.label = `${c.category} (${c.category_nm}) : ${e.cnt} 개`;
        stat.goodsCount += c.cnt;
      });
      this.category = this.category.filter(e=>!this.havingCategoryOnly || e.cnt).sort((a,b)=>a.value == null ? -1 : (b.value == null ? 1 : ((a.value.length - b.value.length)*10 + a.value.localeCompare(b.value))));

      stat.brand_no.forEach(e=>{
        let b = this.brandMap[e._id];
        if (!b) return this.brand.push({value:e._id, label:`[브랜드없음] brand_no - ${utils.ifEmpty(utils.ifNull(e._id, '(null)'), '(빈 문자열)')} : ${e.cnt} 개`, cnt:e.cnt});
        b.cnt = e.cnt;
        b.label = `${b.brand_nm} (${b.brand_nm_kr}) : ${e.cnt} 개`;
      });
      this.brand = this.brand.filter(e=>!this.havingBrandOnly || e.cnt).sort((a,b)=>a.label.localeCompare(b.label));

      this.season = stat.season.map(e=>{
        let label = utils.ifNull(e._id, '(null)');
        if (label === '') label = "(빈 문자열)";
        return {value:e._id, label:`${label} : ${e.cnt} 개`};
      }).sort((a,b)=>a.value == null ? -1 : (b.value == null ? 1 : (a.value.localeCompare(b.value))));
    },
    async getMarginRule(shop_id) {
      this.isBusy = true;
      let j = await postJson('/price/marginRule', {shop_id:shop_id === 'global' && this.selectedShop ? this.selectedShop.shop_id : shop_id});
      if (!j) return;
      let ruleLambda = (e,i)=>{
        let category = '-', brand = '-', season = '-', goods_no = '-', _html = '', direct = '';
        if (e.category && e.category.length) {
          let c = this.categoryMap[e.category[0]];
          let label = `${c.category} (${c.category_nm})`;
          if (e.category.length > 1) {
            category = `${label} 외 ${e.category.length - 1} 개`;
          } else {
            category = label;
          }
          _html += `적용 카테고리 :<br/>&nbsp;&nbsp;&nbsp;${e.category.map(e=>{
            let c = this.categoryMap[e];
            return `<span class="badge badge-light">${c.category} (${c.category_nm})</span>`
          }).join('&nbsp;')}<br/>`
        } else {
          _html += '적용 카테고리 :<br/>&nbsp;&nbsp;&nbsp;<span class="badge badge-success">전체</span><br/>';
        }
        if (e.brand_no && e.brand_no.length) {
          let b = this.brandMap[e.brand_no[0]];
          let label = `${b.brand_nm} (${b.brand_nm_kr})`;
          if (e.brand_no.length > 1) {
            brand = `${label} 외 ${e.brand_no.length - 1} 개`;
          } else {
            brand = label;
          }
          _html += `적용 브랜드 :<br/>&nbsp;&nbsp;&nbsp;${e.brand_no.map(e=>{
            let b = this.brandMap[e];
            return `<span class="badge badge-light">${b.brand_nm} (${b.brand_nm_kr})</span>`
          }).join('&nbsp;')}<br/>`
        } else {
          _html += '적용 브랜드 :<br/>&nbsp;&nbsp;&nbsp;<span class="badge badge-success">전체</span><br/>';
        }
        if (e.season && e.season.length) {
          season = `${e.season[0]}` + (e.season.length > 1 ? ` 외 ${e.season.length - 1} 개` : '');
          _html += `적용 시즌 :<br/>&nbsp;&nbsp;&nbsp;${e.season.map(e=>`<span class="badge badge-light">${e}</span>`).join('&nbsp;')}<br/>`;
        } else {
          _html += '적용 시즌 :<br/>&nbsp;&nbsp;&nbsp;<span class="badge badge-success">전체</span><br/>';
        }
        if (e.goods_no && e.goods_no.length) {
          goods_no = `${e.goods_no[0]}` + (e.goods_no.length > 1 ? ` 외 ${e.goods_no.length - 1} 개` : '');
          _html += `적용 goods_no :<br/>&nbsp;&nbsp;&nbsp;${e.goods_no.map(e=>`<span class="badge badge-light">${e}</span>`).join('&nbsp;')}<br/>`;
        }
        if (e.direct) {
          let kv = Object.entries(e.direct), size = kv.length;
          direct = kv.slice(0, 3).map(([k,v])=>`${k}: ${utils.comma(v)} ${v > 50 ? '원' : '%'}`).join(', ') + (size > 3 ? ` 외 ${size - 3} 개` : '');
          _html = `총 ${size} 개 상품${size>100 ? '(최초 100 개만 보여집니다)' : ''}<br/>`
            + kv.slice(0, 100).map(([k,v])=>`${k}: ${utils.comma(v)} ${v > 50 ? '원' : '%'}`).join('<br/>') + '<br/><br/>';
        }
        if (e.desc) {
          _html += '비고 :<br/>&nbsp;&nbsp;&nbsp;' + e.desc + '<br/>';
        }
        _html += `설정일시 :<br/>&nbsp;&nbsp;&nbsp;<span class="badge badge-light">${e._dt}</span><span class="badge badge-success"> ${e._name}</span>`;
        return {id:i, order:i+1, category, brand, season, goods_no, rate:e.rate, desc:e.desc, _dt:e._dt, _html, direct, _org:e};
      };
      this.rules = j.list.map((p,i)=>{ return {...p, data:p.data.map(ruleLambda)};});
      this.globalRules = j.global.map((p,i)=>{ return {...p, data:p.data.map(ruleLambda)};});
      this.isBusy = false;
    },
    async reorder(event, shop_id, page) {
      let rules = (shop_id === 'global' ? this.globalRules : this.rules).filter(p=>p.page === page)[0].data;
      rules.forEach((e,i)=>{e.order = i+1});
      let j = await postJson('/price/setMarginRule', {type:'reorder', shop_id:shop_id || this.selectedShop.shop_id, rule:rules.map(e=>e._org._t), page});
    },
    async addMarginRule(shop_id) {
      if (!(shop_id === 'global' ? this.globalRules[this.globalPageIndex] : this.rules[this.pageIndex])) return;
      let rule = {...this.form};
      if (this.formIndex === 0) {
        Object.entries(rule).forEach(([k,v])=>{
          if (k === 'goods_no') {
            rule[k] = v = v && v.trim() ? v.trim().split(/\D+/g).map(e=>+e).filter(e=>e) : null;
            if (v === null) delete rule[k];
          } else if (utils.typeOf(v) === 'array') {
            if (!v.length) delete rule[k];
            else rule[k] = rule[k].map(e => e.value);
            if (k === 'brand') {
              rule.brand_no = rule.brand;
              delete rule.brand;
            }
          } else if (k.endsWith('_direct')) {
            delete rule[k];
          }
        });
        let j = await postJson('/price/setMarginRule', {type:'add', shop_id, rule, page:(shop_id === 'global' ? this.globalRules[this.globalPageIndex] : this.rules[this.pageIndex]).page});
        if (!j) return;
      } else {
        let direct = {};
        rule.goods_direct.split(/\r?\n/).filter(e=>e.trim()).forEach(e=>{
          let [goods_no, price] = e.trim().split(/\s/).map(e=>+e.replace(/,/g, ''));
          direct[goods_no] = price;
        });
        let j = await postJson('/price/setMarginRule', {type:'add', shop_id, rule: {direct, desc: rule.desc_direct}, page:(shop_id === 'global' ? this.globalRules[this.globalPageIndex] : this.rules[this.pageIndex]).page});
        if (!j) return;
      }
      this.getMarginRule(shop_id);
    },
    async removeMarginRule(row, shop_id) {
      if (!confirm('규칙을 정말로 삭제하시겠습니까?')) return;
      let obj = JSON.parse(JSON.stringify(row._org));
      let j = await postJson('/price/setMarginRule', {type:'remove', shop_id, rule:obj, page:(shop_id === 'global' ? this.globalRules[this.globalPageIndex] : this.rules[this.pageIndex]).page});
      if (!j) return;
      this.getMarginRule(shop_id);
    },
    async newGlobalPage() {
      let maxPage = this.globalRules.map(e=>e.page).reduce((a,b)=>a>b?a:b, 0);
      let j = await postJson('/price/addMarginPage', {shop_id:'global', page:maxPage + 1});
      if (!j) return;
      this.globalRules.push({page:maxPage + 1, data:[], data_cnt:0});
    },
    async renameGlobalPage(page) {
      let name = prompt('페이지명을 입력해주세요');
      if (name == null) return;
      let j = await postJson('/price/renameMarginPage', {shop_id:'global', name, page});
      if (!j) return;
      this.getMarginRule('global');
    },
    async activateGlobalPage(page, active) {
      if (!confirm(`${page} page 의 규칙을 ${active ? '' : '비'}활성화 하시겠습니까?`)) return;
      let j = await postJson('/price/activateMarginPage', {shop_id:'global', active, page});
      if (!j) return;
      this.selectedShop && this.getMarginRule(this.selectedShop.shop_id);
      this.getMarginRule('global');
    },
    async removeGlobalPage(page) {
      if (!confirm(`${page} page를 삭제하시겠습니까?`)) return;
      let j = await postJson('/price/removeMarginPage', {shop_id:'global', page});
      if (!j) return;
      this.selectedShop && this.getMarginRule(this.selectedShop.shop_id);
      this.getMarginRule('global');
    },
    async newPage() {
      let maxPage = this.rules.map(e=>e.page).reduce((a,b)=>a>b?a:b, 0);
      let j = await postJson('/price/addMarginPage', {shop_id:this.selectedShop.shop_id, page:maxPage + 1});
      if (!j) return;
      this.rules.push({page:maxPage + 1, data:[], data_cnt:0});
    },
    async renamePage(page) {
      let name = prompt('페이지명을 입력해주세요');
      if (name == null) return;
      let j = await postJson('/price/renameMarginPage', {shop_id:this.selectedShop.shop_id, name, page});
      if (!j) return;
      this.getMarginRule(this.selectedShop.shop_id);
    },
    async activatePage(page, active) {
      if (!confirm(`${page} page 의 규칙을 ${active ? '' : '비'}활성화 하시겠습니까?`)) return;
      let j = await postJson('/price/activateMarginPage', {shop_id:this.selectedShop.shop_id, active, page});
      if (!j) return;
      this.getMarginRule(this.selectedShop.shop_id);
    },
    async removePage(page) {
      if (!confirm(`${page} page를 삭제하시겠습니까?`)) return;
      let j = await postJson('/price/removeMarginPage', {shop_id:this.selectedShop.shop_id, page});
      if (!j) return;
      await this.getMarginRule(this.selectedShop.shop_id);
      if (this.rules.length === 0) {
        // shops 를 갱신하여 shortcut 버튼을 없앤다.
        this.selectedShop = null;
        this.init();
      }
    },
    handleDragover(e) {
      e.stopPropagation();
      e.preventDefault();
      e.dataTransfer.dropEffect = 'copy';
    },
    async handleFile(event) {
      let file = (event.dataTransfer || event.target).files[0];
      if (!file || !file.name.endsWith('xlsx') && !file.name.endsWith('xls')) return utils.alert('xlsx 파일을 업로드해주세요');
      let {headers, rows} = await readXlsx(file);
      this.uploadXlsxData(event.target.dataset.type, headers, rows);
    },
    async uploadXlsxData(type, headers, rows) {
      // 업로드 전 내용을 체크한다.
      // 1. 컬럼들이 올바른가
      // 2. 컬럼들 데이터 형식이 올바른가
      // 3. global 인지 shop 인지 선택
      console.log(type, headers, rows);

      let colNameMap = {}, cols = type === 'rule' ? MARGIN_RULE_COLUMNS : MARGIN_PRICE_COLUMNS;
      cols.forEach(e=>{
        colNameMap[e.code] = e;
      });
      let unknown = headers.filter(e=>!colNameMap[e]);
      if (unknown.length) return alert('알 수 없는 컬럼들이 있습니다:\n'+unknown.join('\n'));
      let required = cols.filter(e=>e.required).map(e=>e.code).filter(e=>!~headers.indexOf(e));
      if (required.length) return alert('필수 컬럼이 빠져있습니다:\n'+required.join('\n'));

      let wrongRows = [];
      rows.forEach((e, i)=>{
        let wrongCols = [];
        headers.forEach(h=>{
          let tester = colNameMap[h].test;
          if (e[h] != null && e[h] !== '' && tester && !tester.test(e[h])) {
            wrongCols.push(`${h}: ${e[h]}`);
          }
          if ((e[h] == null || e[h] === '') && colNameMap[h].required) {
            wrongCols.push(`${h}: (비어있음)`);
          }
        });
        if (wrongCols.length) wrongRows.push({idx:i, cols:wrongCols});
      });
      if (wrongRows.length) return alert('다음 컬럼들의 값이 올바르지 않습니다:\n'+wrongRows.map(e=>`${e.idx+2} 번째줄 ${e.cols.map(e=>e).join(', ')}`).join('\n'));
      this.uploadTargetModal = true;
      this.xlsxData = {type, headers, rows};
    },
    async uploadMarginRule(shop_id) {
      this.uploadTargetModal = false;
      let pageObj = shop_id === 'global' ? this.globalRules[this.globalPageIndex] : this.rules[this.pageIndex];
      if (!pageObj) return;
      let rule = [];
      if (this.xlsxData.type === 'rule') {
        this.xlsxData.rows.forEach(e=>{
          let row = {...e, desc:this.uploadDesc};
          'category,season'.split(',').filter(k=>row[k] != null).forEach(k=>{
            row[k] = row[k].split(',');
          });
          'brand_no,goods_no'.split(',').filter(k=>row[k] != null).forEach(k=>{
            row[k] = row[k].split(',').map(e=>+e);
          });
          rule.push(row);
        });
      } else {
        let direct = {};
        this.xlsxData.rows.forEach(e=>{
          direct[e.goods_no] = e.price;
        });
        rule.push({direct, desc: this.uploadDesc});
      }
      this.uploadDesc = '';
      let j = await postJson('/price/setMarginRule', {type:'upload', shop_id, rule, page:pageObj.page});
      if (!j) return;
      this.getMarginRule(shop_id);
    },
    xlsxSample(type) {
      if (type === 'rule') {
        let cols = 'category,brand_no,season,goods_no,rate'.split(',');
        down([{category:'009003,009004', brand_no:'333,4444', rate:5}, {goods_no:'2334142', rate:7}], cols, cols, `RuleSample`, 'xlsx');
      } else if (type === 'price') {
        let cols = 'goods_no,price'.split(',');
        down([{goods_no:'2213459', price:550000}, {goods_no:'2334142', price:1070000}], cols, cols, `PriceSample`, 'xlsx');
      }
    }
  }
}
</script>
